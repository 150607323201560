@charset "UTF-8";


/* Slider */

.unslider {
	position: relative;
}


.unslider ul li {
		 height: 630px;

		 @media only screen and (max-width : 1024px) {
				height: 500px;
		 }

		 @media only screen and (max-width : 768px) {
				height: 280px;
		 }

		 .slider-content {
		 	position:relative;
		    color: #fff;
		    @include vertical-center();
		    max-width: 360px;
		    padding: 15px 30px;
		    margin: 0 auto;
		    border: 1.5px solid hsla(0,0%,100%,.6);
		    text-align: center;
		    font-family: $helvetica-bold-cond;
		    font-size:1.8rem;
			

			@media only screen and (max-width : 768px) {
			    max-width: calc(100% - 200px);
			    padding: 15px 15px;
			    margin: 0 auto;
			    font-size: 1.5rem;
			}


			@media only screen and (max-width : 480px) {
			    max-width: calc(100% - 150px);
			    padding: 15px;
			    margin: 0 auto;
			    font-size: 1.3rem;
			}



		 }
}


nav.unslider-nav {
	position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
	
	@media only screen and (max-width : 768px) {
		display: none;
	}

}




/* Half content areas */

.home-content-areas {
	position: relative;
    display: table;

	&:after {
		content: "";
		position: absolute;
		z-index: -1;
	    top: 0;
	    right: 0;
	    width: 50%;
	    height: 100%;
	    background-color: $v-light-grey; 

	     @media only screen and (max-width : 768px) {
				display: none;
		 }
	}

	.row div {
		padding: 3rem;

		@media only screen and (max-width : 768px) {
			padding: 2rem;
		}
	}
	
	h3 {
		font-size: 2.5rem;
		color: $dark-blue;
		font-family: $helvetica-bold;
		margin-bottom: 0;

		@media only screen and (max-width : 768px) {
			font-size: 2rem;
		}
	}

	p {
		color: $cyan-blue;
		font-size:1.3rem;
		margin-bottom: 0;
		line-height: 1.2;
		strong {
			color: $dark-blue;
			font-family: $helvetica-bold;
		}

		@media only screen and (max-width : 768px) {
		    font-size: 1.2rem;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 20px 0 0 0;
		li {
			color: $dark-blue;
		    font-size: 1.3rem;
		    padding-left: 2.3rem;
		    position: relative;
			

			@media only screen and (max-width : 768px) {
		        font-size: 1.1rem;
		    }


  			&::before {
				content: "• ";
				color: $cyan-blue;
			    left: 0px;
			    position: absolute;
			}
		}
	}

	@media only screen and (max-width : 768px) {
			.row div:nth-of-type(2) {
				background-color: $v-light-grey; 
			}
	}


}
