@charset "UTF-8";

.cabin{
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 125px;
  overflow: hidden;
  padding: 10px;
  background:#0574c5;
  border: 2px solid #004489;
  margin: 20px 0px;


  a {
    &:hover {
      opacity: 1;
    }
  }

  span {
    &.line {
      height: 160px;
      top: 5px;
      width: 15px;
      background: none;
      border-left: 2px solid rgba(0,68,137,0.3);
      border-right: 2px solid rgba(0,68,137,0.3);
      position:absolute;
    }
    
    &.one {
      left: 5%;
    }
    &.two {
      left: 16%;
    }
    &.three {
      left: 27%;
    }
    &.four {
      left: 38%;
    }
    
    &.five {
      left: 49%;
    }
    
    &.six {
      left: 60%;
    }
  }

  .cabin-text {
    font-family: eurostile-extended;
    color: white;
    font-size: 18px;
    line-height:25px;
    @include vertical-center();
    position:absolute;
    font-weight: 700;
    left: 20px;


    @media only screen and (max-width: 1180px){
      font-size: 16px;
      line-height: 22px;
      z-index: 9;
    }


  }

  .doorway-1{
    perspective: 125px;
    position: absolute;
    width: 60px;
    height: calc(100% - 17px);
    right: 10px;
    //background-color: #f4faff;
    background: url('../images/cabin-background.jpg') no-repeat;
      background-size:cover;
      background-position: 50%;
    overflow: hidden;
    border: 2px solid rgba(0,68,137,.9);

    .door-1{
      @include basic-transition(1s);
      box-shadow: -5px 3px 2px rgba(0,0,0,0.5);
      transition: transform 1s;
      transform-style: preserve-3d;
      transform-origin: left center;
      width: 100%;
      height: 100%;
      background:#0574c5;
      border: none;
    }
    
  
    .handle {
      position:absolute;
      width:10px;
      height: 2px;
      background:silver;
      top: 50%;
      right: 3px;
      z-index: 9;

      span {
        background:silver;
        height: 15px;width:2px;
        position:absolute;
        right: -1px;
        top:-5px;
      }
    }
  }
  
  &:hover{
    .door-1{
      transform: rotateY(45deg);
      box-shadow: -5px 3px 2px rgba(0,0,0,0.5);
      border:.5px solid rgba(0, 68, 137, 0.9);
      border-left: none;
    }
    
    .handle {
       -webkit-transform: rotate(-40deg); 
      -ms-transform: rotate(-40deg); 
          transform: rotate(-40deg);
      top: 55%;
      span {
          -webkit-transform: rotate(40deg); 
          -ms-transform: rotate(40deg); 
          transform: rotate(40deg);
      }
    }
  }
}