@charset "UTF-8";

.blue-strip {
 @include blue-gradient();
 margin-bottom:0;
  h2 {
    font-family: $helvetica-light;
    color: white;
    text-transform: uppercase;
    font-size: 2.1rem;
	
	@media only screen and (max-width : 1024px) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width : 768px) {
        font-size: 1.3rem;
    }
  }

  @media only screen and (max-width : 1024px) {
  	padding: 2rem !important;
  }

  @media only screen and (max-width : 768px) {
	padding: 1rem !important;
  }


}
