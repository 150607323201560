@charset "UTF-8";

* {
  outline: 0;
}

body {
  background-color: $white;
  font-family: $helvetica-light, sans-serif;
}

b, strong {
  font-family:$helvetica-bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $helvetica-bold, sans-serif;
}

a {
  opacity:1;
  @include basic-transition(0.3s);
  &:hover {
    opacity:0.7;
    @include basic-transition(0.3s);
  }
}

p {
      line-height: 125%;
      color: $text-blue;
}

span {
  &.green {
    color: #12bf12;
  }
}

.wrapper {
  background-color: $white;
}

.section {
  padding: rem(24px) 0;
}

.no-margin {
  margin: 0 !important;
}

.pagination {
  .page-number {
    padding: 0;
    width: rem(32px);
    height: rem(32px);
    border-radius: 50%;
    display: inline-block;
  }
  .current {
    background-color: $cyan-blue;
  }
}


.full-width {
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   max-width: initial;
}