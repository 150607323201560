@charset "UTF-8";


@mixin basic-transition($speed) {
  transition: all $speed ease-in-out;
  -webkit-transition: all $speed ease-in-out;
  -moz-transition: all $speed ease-in-out;
}

@mixin vertical-center() {
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

@mixin blue-gradient(){
  background: $gradient-light; /* Old browsers */
  background: -moz-linear-gradient(left, #0088c6 0%, #1756a7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #0088c6 0%,#1756a7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #0088c6 0%,#1756a7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0088c6', endColorstr='#1756a7',GradientType=1 ); /* IE6-9 */
}

@mixin parallax($image) {
  background: url($image) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin no-flickr(){
  -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform-style: preserve-3d;
   -webkit-backface-visibility: hidden;
}
