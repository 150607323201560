@charset "UTF-8";

/* banner */

.std-content {
	padding: 3rem 0;

	.content {
		
		a {
			&.download {
		    font-size:20px;
		    color: #409fd8;
		    float:left;
		    width: 50%;
		    margin-bottom: 40px;
		    position: relative;
		    padding-left: 80px;

			    &:before {
			      content: " ";
			      background: url('../images/download-icon.svg') no-repeat;
			      background-size: 47px 56px;
			      background-position: 50%;
			      width: 47px;
			      position: absolute;
			      height: 56px;
			      left: 0;
			      top: -9px;
			    }
			}

			&.no-before {
				padding-left: 0px;
				margin-bottom: 60px;
				&:before {
			      content: none !important;
			    }
			}
		}

		p:first-child {
			color: $cyan-blue;
		    font-size: 1.3rem;
		    margin-top: 0;
		    line-height: 1.2;
		    line-height: 125%;
		    text-transform: uppercase;
		}

		 p {
			color: $text-blue;
			font-size: 1rem;
			margin: 20px 0px;
		 }

		 h1,h2,h3,h4 {
		 	color: $cyan-blue;
		 }

		 ul {
		 	list-style-type: none;
		 	margin-left: 0;
		 	li {
		 		color: $text-blue;
				font-size: 1rem;
				padding-left: 2rem;
				position: relative;

				&:before {
					content: "• ";
				    color: #27aae1;
				    left: 0;
				    position: absolute;
				}
		 	}
		 }
	}


	@media only screen and (max-width: 768px){
		 padding: 2rem 1rem;
	}
}


.banner-container {
	position:relative;
	width: 100%;
	height: 340px;

	&:before {
		position:absolute;
		content: " ";
		width: 100%;
		height: 100%;
		background: rgba(27,92,170,0.4);
	}

	&.article {
		border-bottom: 1px solid rgb(243, 243, 243);
		&:before {
			content: none;
			display: none;
		}
	}
	.caption-text {
	    position: relative;
	    color: $white;
	    top: 50%;
	    transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	    -webkit-transform: translateY(-50%);
	    -moz-transform: translateY(-50%);
	    max-width: 400px;
    	padding: 15px 25px;
	    margin: 0 auto;
	    text-align: center;
	    font-family: $helvetica-bold-cond;
	    font-size: 2.3rem;
	    text-transform: uppercase;
	}
}


@media only screen and (max-width: 1024px){
	.banner-container {
	    height: 500px;
	}
}

@media only screen and (max-width: 768px){
	.banner-container{
	    height: 280px;

	    .caption-text {
	    	font-size: 1.8rem;
	    }
	}
}

@media only screen and (max-width: 480px){
	.banner-container{
	    height: 280px;

	    .caption-text {
    		font-size: 1.8rem;
		}

	}
}

/* Sub menu */

.standard-sub-menu {
	position: relative;
	background: $q-light-grey;
	border-bottom: 3px solid $cyan-blue;

	.title {
		color: white;
		font-size:28px;
		font-family: $helvetica-bold-cond;
		width:100%;
		padding: 10px 20px;
		background: $cyan-blue;
		text-transform: uppercase;
		

		@media only screen and (max-width : 1280px) {
			font-size: 24px;
		}
	}

	.list-content {
		padding: 10px 20px;
		p {
			color:$text-blue;
		}

		&.contact {
			a, p  {
				font-size:20px;
			}

			a {
				margin: 0;
				line-height: 125%;
			}
		}
	}

	a {
		color: $text-blue;
		font-size: 20px;
	    line-height: 22px;
	    margin: 12px 0;
	    display: block;
		font-family: $helvetica-light;


		@media only screen and (max-width : 1280px) {
			  font-size: 18px;
		}

		
	}


}


