@charset "UTF-8";


.no-js {
  @include breakpoint(small only) {
    .main-menu {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

.is-dropdown-submenu-parent {
    position: relative;
}

.dropdown .is-dropdown-submenu a {
    background: none;
    color: #0073bc;
    display: table-cell;
    vertical-align: middle;
    border: 0.5px solid rgb(63, 158, 216) !important;
        font-size: .7rem;
            padding: 0px 5px;
}

.menu-button {
    display: inline-block;
    background-color: transparent;
    border: none;
}

.burger-icon {
  @include burger(25px, 3px, 5px, #ffffff);
}

.menu-button.is-active .burger-icon {
  @include burger-to-cross;
}


header {

padding: 25px 0px;
background: $gradient-light; /* Old browsers */
background: -moz-linear-gradient(left, #0088c6 0%, #1756a7 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, #0088c6 0%,#1756a7 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, #0088c6 0%,#1756a7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0088c6', endColorstr='#1756a7',GradientType=1 ); /* IE6-9 */


  @media only screen and (max-width : 768px) {
    padding: 13px 0px 0px 0px;
  }

  .title {
    width: rem(120px);
    max-width: 100%;
    display: block;
  }

  .header-contact {
    display: block;
    text-align: right;

    span {
      &.email{
        margin-left: 10px;
      }
    }

    &:after {
      clear:both;
    }
    a,span {
      text-decoration: none;
      color: white;
      font-size: 0.8rem;
    }
  }

  .logo-area {
    display: inline-block;
    vertical-align: bottom;
    
    @media only screen and (max-width : 768px) {
        display: table;
        vertical-align: bottom;
        margin: 10px auto 5px auto;
    }
    
    img {
      width: 150px;
      height: 155px;

      @media only screen and (max-width : 768px) {
        width: 120px;
        height: 124px;
      }

      @media only screen and (max-width : 480px) {
        width: 100px;
        height: 104px;
      }
    }
  }

  .menu-area {
    display: inline-block;
    vertical-align: bottom;
    width: calc(100% - 155px);
    text-align: right;
    padding-left: 10px;

    @media only screen and (max-width : 768px) {
        width: 100%;
        padding-left: 0;
        margin-top: 10px;

        .title-bar {
            background: none;
            color: #fefefe;
            align-items: center;
            position: absolute;
            top: 15px;
        }
    }

    .main-menu {
      z-index: 9;
      position: relative;

      .dropdown.menu>li.opens-right>.is-dropdown-submenu {
        top: 100%;
        right: auto;
        left: 0;
        margin-left: 0;
        list-style-type: none;
        border: none;
        position: absolute;
        left: 0;
        background: none;
          
          @media only screen and (max-width : 768px) {
              width: 100%;
          }
      }

      .dropdown.menu>li.is-dropdown-submenu-parent>a:after {
        display: none;

         @media only screen and (max-width : 768px) {

            display: block;
         }
      }

      .dropdown.menu .is-active>a {
        background: #409fd8;
        color: white;
      }

      ul.menu{
          display: inline-block;
          margin-bottom: 5px;
      }
    }


  

    h1 {
        color: #fff;
        font-family: eurostile-extended;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: center;
        text-transform: uppercase;

      @media only screen and (max-width : 1290px) {
         font-size: 1.8rem; 
      }

      @media only screen and (max-width : 1180px) {
         font-size: 1.4rem; 
      }
      
      @media only screen and (max-width : 1080px) {
        font-size: 1.3rem;
        line-height: 1;
      }

      @media only screen and (max-width : 768px) {
        display: none;
      }

    }


  nav  li.current-menu-item a {
          background: $white;
          color: $standard-blue;

          &:hover {
               background: $white;
               color: $standard-blue;
          }

  }


    nav { 
    text-align:left;


      li {
          margin-left: 3px;
          text-align: center;  
          width: 116px; 
          float: left;  

          ul {
            li {
                margin-left: 0px;
                background: #fff;
                color: #0073bc;
                padding: 0;
                display: table;
                height: 40px;

                @media only screen and (max-width : 768px) {
                    margin-left: 0;
                    background: #fff;
                    color: #0073bc;
                    padding: 0;
                    display: table;
                    height: 40px;
                    width: 100%;
                  }

              a {
                @media only screen and (max-width : 768px) {
                    background: #1c58a5;
                }

                &:hover {
                    text-decoration: none;
                    opacity: 1;
                    @include transition (all 0.5s ease-in-out); 
                   // background: #1c58a5;
                }
              }

              ul {
                top: 100%;
                right: auto;
                left: 0;
                margin-left: 0;
                list-style-type: none;
                border: none;
                background: none;
              }



            }



          }


          &:first-child {
            margin-left: 0;
          }

          a {
             padding: 11px 0!important;
             text-align: center !important;
              background: $light-blue;
              text-transform: uppercase;
              font-family: $helvetica-cond;
              color: $white;
              font-size: 12px;
              @include transition (all 0.5s ease-in-out);

              &:last-child {
                border: 0;
              }
              
              &:hover {
                text-decoration: none;
                opacity: 0.7;
                @include transition (all 0.5s ease-in-out);
              }

          }


          @media only screen and (max-width : 1290px) {
              width: 115px;
              a{
                  font-size: 0.8rem;
              }
          }

          @media only screen and (max-width : 1180px) {
                width: 105px;
                a{
                    font-size: 0.7rem;
                }
          }

          @media only screen and (max-width : 1180px) {
              width: 125px;
              margin-right: 3px;
              margin-left: 0;
              margin-top: 3px;
          }

          @media only screen and (max-width : 768px) {
              width: 100%;
              margin-right: 3px;
              margin-left: 0;
              margin-top: 1px;
              a {
                 padding: .7rem 1rem;
                  font-size: 0.8rem;
              }
          }


      }


     

     }

  }


}
