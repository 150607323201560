@charset "UTF-8";

/* Individual accommodation page */

.std-content .content .accom-ind {
	margin-bottom: 40px;
	h1 {
		color: #e71e35;
		font-size:40px;
  		  margin-bottom: 0;
	}

	h2 {
		color: #409fd8;
		font-family: $helvetica-light;
		font-size:25px;
	}
}