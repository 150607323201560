@charset "UTF-8";

/* Contact page */

.wpcf7-form{
	input[type="text"],input[type="email"], textarea {
		background: none;
		margin-bottom:15px;
		font-family: $helvetica-cond;
		color: $light-grey;
		border: 1px solid $light-grey;
		resize: none;
		font-size:22px;
		height: auto;
    	padding: 10px 0 10px 20px;
    	outline: none;
	}

	textarea {
		height: 170px;
	}

	br {
		display: none;
	}

	input[type="submit"]{
		background: $cyan-blue;
		font-family: $helvetica-bold-cond;
		color: $white;
		font-size: 25px;
		padding: 10px 60px;
		border: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-transform: uppercase;
	}
}

.additional-depots {
	color: $text-blue;
	margin-top:60px;

	h4 {
		margin-bottom: 30px;
	}

	 p{
	 	margin-top: 15px;
	 	font-size:18px;
	 }
}