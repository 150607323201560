@charset "UTF-8";

/* Main accommodation page */

.accom-main {

	.accom-block{
		display: block;
		position:relative;
		height:180px;
		margin-bottom: 1.8rem;
		border-radius: 8px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
		    content: " ";
		    width: 100%;
		    height: 100%;
		    background: rgba(39,170,225,.6);
		    border-radius: 8px;
		}

		&:hover {
			.bordered {
				width: calc(100%);
				height: calc(100%);
				background: rgba(64, 159, 216,0.5);
				border: 2px solid $white;
				border-radius: 8px;
			}

			h2 {
				font-size:27px;
			}
		}

		.bordered {
			width: calc(100% - 70px);
    		height: calc(100% - 70px);
			border: 2px solid rgba(255,255,255,0.6);
			@include basic-transition(0.5s);
			position:relative;
			display:block;
			@include vertical-center();
			margin: 0 auto;


		}

		h2 {	
				width: calc(100% - 70px);
				color: white;
				font-family: $helvetica-bold-cond;
				font-size:25px;
			    text-transform: uppercase;
			    background: transparent;
			    position: absolute;
			    @include vertical-center();
			    @include basic-transition(0.5s);
			    left: 0;
			    right: 0;
			    margin: 0 auto 0 auto;
				background: transparent;
			}

	}

	
}