@charset "UTF-8";


$helvetica-light: "Helvetica LT W01 Light";
$helvetica-roman:"Helvetica LT W01 Roman";
$helvetica-bold: "Helvetica LT W01 Bold";
$helvetica-cond: "Helvetica LT W01 Condensed";
$helvetica-bold-cond: "Helvetica LT W01 Bold Cond";
$helvetica-black-cond: "Helvetica LT W01 Black Cond";
$helvetica-bold-narrow: "Helvetica LT W01 Bold Narrow";

$eurostile-extended: "eurostile-extended";



