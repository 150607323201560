@charset "UTF-8";

.tabs {
  margin-top: 20px;
}

.std-content .content ul li.tabs-title {
    width: calc(100% / 3);
    text-align: center;
    background: #ededed;
    position:relative;
    padding-left: 0;

    &:before {
      content: none;
    }

    &.is-active {
      background: #27aae1;
        &:before {
          content: "";
          background:url('../images/tab-arrow.png') no-repeat;
          background-size: 25px 13px;
          width: 25px;
          height: 13px;
          position:absolute;
          bottom: -12px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
    }

    &:hover {
      opacity: 1;
      a {
        opacity: 1;
      }
      &:before {
        opacity: 1;
      }
    }
}

.std-content .content .tabs-panel{
  padding: 1rem 0 1.5rem 0;


  a.download {
    font-size:20px;
    color: #409fd8;
    float:left;
    width: 50%;
    margin-bottom: 30px;
    position: relative;
    padding-left: 80px;

    &:before {
      content: " ";
      background: url('../images/download-icon.svg') no-repeat;
      background-size: 47px 56px;
      background-position: 50%;
      width: 47px;
      position: absolute;
      height: 56px;
      left: 0;
      top: -9px;
    }
  }

  p,p:first-child, li {
    color: #1b57a6;
    font-size: 1rem;
    text-transform: none;
  }
}

.tabs-content {
  margin-top: 20px;
  border-bottom: 1px solid rgba(64,159,216, 0.5);
  
   @media only screen and (max-width : 768px) {
      .fancy-image {
          width: 46% !important;
          height: 150px !important;
      }
    }
}

.tabs li:first-child, .tabs li:first-child a {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tabs li:last-child, .tabs li:last-child a {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tabs li a {
  font-family: $helvetica-cond;
  font-size: 20px;
  text-transform:uppercase;

  @media only screen and (max-width : 1280px) {
    font-size: 18px;
  }
}

.tabs-title>a {
  padding: 1.25rem 0.5rem;

  @media only screen and (max-width : 1024px) {
   //height: 70px;
  }
}

