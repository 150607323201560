@charset "UTF-8";

$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1080px,
  xxlarge: 1280px,
) !default;

$breakpoint-classes: (small medium large) !default;

@import "../../../../node_modules/foundation-sites/scss/util/util";
 
$global-width: rem-calc(1280);


$tab-background-active: $cyan-blue;
$tab-active-color: $white;
$tab-content-border: transparent;
$tab-background: $q-light-grey;
$tab-color: $text-blue;
$tab-background: transparent;


.callout {
	border: none !important;
}