@charset "UTF-8";

.three-icons-strip > div {

  background: $standard-blue;
  padding: 3rem 2rem;
  
  @media only screen and (max-width : 768px) {
    padding: 2rem 1rem;
  }
  
  &:nth-child(2) {
      background: $cyan-blue;
  }
}

.three-icons-strip {
 

  img{
    margin-bottom: 20px;
  }

  h3 {
    font-family: $helvetica-roman;
    color: white;
    text-transform: uppercase;
    font-size: 1.3rem;
    margin: 0;
    
    @media only screen and (max-width : 768px) {
        font-size: 1rem;
    }
  }
}
