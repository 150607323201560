@charset "UTF-8";

$white: #fff;
$black: #231f20;

$gradient-light: #0088c6;
$gradient-dark: #1756A7;

$v-light-grey: #dcddde;
$q-light-grey: #ededed;
$light-grey: #939598;
$dark-grey: #6D6E71;

$dark-blue: #1856A7;
$standard-blue: #0073BC;
$light-blue: #409FD8;
$cyan-blue: #27AAE1;

$text-blue: #1b57a6;

$red: #E81C34;

$shadow: rgba(0, 0, 0, .1);
