@charset "UTF-8";

footer {
  background-color: $white;
  position: relative;
  color: $cyan-blue;

  .footer-contact-wrapper {
    padding: 0 3rem;
    
    @media only screen and (max-width : 768px) {
        padding: 0 1rem;
    }
  }


  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 30px;
    top: 0;
    left: 0;
    right: 0;
    background: -ms-linear-gradient(90deg,transparent 0,#030303 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#030303' ,GradientType=0)";
    background: linear-gradient(0deg,transparent,#595959);
    opacity: .7;
    filter: alpha(opacity=70) progid:DXImageTransform.Microsoft.Alpha(opacity=70) progid:DXImageTransform.Microsoft.gradient(startColorstr='#030303',endColorstr='#000000' , GradientType=0);
  }

  strong {
      color: $dark-blue;
      font-family: $helvetica-bold;
  }


  h3 {
    font-size: 2.5rem;
    color: $dark-blue;
    font-family: $helvetica-bold;
    margin-bottom: 0;
    
    @media only screen and (max-width : 768px) {
        font-size: 1.6rem;
    }
   
  }

  a {
    text-decoration: none;
  }

  p, a{
    color: $cyan-blue;
    font-size:1.3rem;
    margin-bottom: 0;
    line-height: 1.2;
    
    @media only screen and (max-width : 768px) {
      font-size: 1.1rem;
    }
  }

  .contact-user {
    display: inline-block;
    vertical-align: middle;
    width: 110px;

    @media only screen and (max-width : 768px) {
        display: none;
    }
  }

  .contact-details {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 110px);
    padding-left: 20px;
    
    @media only screen and (max-width : 768px) {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      padding-left: 0;
      margin-bottom: 20px;
    }
  }

  .separator {
    background: $cyan-blue;
    height: 1px;
    margin: 50px 0px 20px 0px;
  }


  .social-icons {
    .icon {
      display: inline-block;
      width: 50%;
      margin: 0.5rem 0;
      
      @media only screen and (max-width : 768px) {
        width: 100%;
        margin: .5rem 0;
      }

      img {

      }
    }
  }

  .logos {
    div {
      @media only screen and (max-width : 768px) {
        text-align:left;
        width: 33.3%;
      }
    }
  }

  .footer-contact {
    padding: 3rem 0 1rem 0;
  }


  .footer-nav-area {
    background: $light-grey;
    padding: 1.5rem 2rem;
    

    @media only screen and (max-width : 768px) {
        padding: 1rem 1rem;
    }

    ul {
      margin-bottom: 5px;

      li {

        @media only screen and (max-width : 768px) {
          margin-top: 3px;
        }

       a {
          color: #fff;
          font-size: .75rem;
          padding: 0 15px;
          border-right: 1px solid white;
          
          @media only screen and (max-width : 768px) {
            font-size: .7rem;
            padding: 0 17px 0px 0px;
            border-right: none;
          }
        }
      }
    }

    p,a{
      font-size: .75rem;
      color: black;
      line-height: 1.2;
    }
    
    ul li:first-child a {
      padding-left: 0;
    }

    ul li:last-child a {
      border-right: none;
    }

  }

}
